import React from "react"
import config from "../config";
import Landing from "../pages/Utility/landing";

const userRoutes: any[] = [];

const authRoutes = [
    { path: "/", component: Landing },
  ].map(route => ({...route, path: config.root + route.path}));

console.log({ userRoutes, authRoutes })
export { userRoutes, authRoutes }