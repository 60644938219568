import type { ChangeLayout, ChangePreloader, ChangeSidebarTheme, ChangeSidebarType, ChangeTopBarTheme, ShowRightSidebar, ShowSidebar, ToggleLeftMenu, ChangeMenu, ChangeLayoutState, ChangeMenuState, ChangePreloaderState, ChangeSidebarThemeState, ChangeSidebarTypeState, ChangeTopBarThemeState, ShowRightSidebarState, ShowSidebarState, ToggleLeftMenuState } from './types';
import { toggleLeftmenu, changeMenu, } from "./actions";

// @flow
import {
    CHANGE_LAYOUT,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_TYPE,
    CHANGE_TOPBAR_THEME,
    SHOW_RIGHT_SIDEBAR,
    CHANGE_PRELOADER,
    TOGGLE_LEFTMENU,
    SHOW_SIDEBAR,
    CHANGE_MENU,
  } from "./actionTypes"
  
  const INIT_STATE: LayoutState = {
    layoutType: "vertical",
    leftSideBarTheme: "dark",
    leftSideBarType: "default",
    topbarTheme: "light",
    isPreloader: false,
    menuType: "default",
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
  }
  
  export type LayoutActionType = ChangeLayout
    | ChangeMenu
    | ChangePreloader
    | ChangeSidebarTheme
    | ChangeSidebarType
    | ChangeTopBarTheme
    | ShowRightSidebar
    | ShowSidebar
    | ToggleLeftMenu
  
    export type LayoutState = ChangeLayoutState
    & ChangeMenuState
    & ChangePreloaderState
    & ChangeSidebarThemeState
    & ChangeSidebarTypeState
    & ChangeTopBarThemeState
    & ShowRightSidebarState
    & ShowSidebarState
    & ToggleLeftMenuState



  const Layout = (state = INIT_STATE, action: LayoutActionType): LayoutState => {
    switch (action.type) {
      case CHANGE_MENU:
        return {
          ...state,
          menuType: action.payload
        }
      case CHANGE_LAYOUT:
        return {
          ...state,
          layoutType: action.payload,
        }
      case CHANGE_PRELOADER:
        return {
          ...state,
          isPreloader: action.payload,
        }
  
      case CHANGE_SIDEBAR_THEME:
        return {
          ...state,
          leftSideBarTheme: action.payload,
        }
      case CHANGE_SIDEBAR_TYPE:
        return {
          ...state,
          leftSideBarType: action.payload.sidebarType,
        }
      case CHANGE_TOPBAR_THEME:
        return {
          ...state,
          topbarTheme: action.payload,
        }
      case SHOW_RIGHT_SIDEBAR:
        return {
          ...state,
          showRightSidebar: action.payload,
        }
      case SHOW_SIDEBAR:
        return {
          ...state,
          showSidebar: action.payload,
        }
      case TOGGLE_LEFTMENU:
        return {
          ...state,
          leftMenu: action.payload,
        }
  
      default:
        return state
    }
  }
  
  export default Layout
  