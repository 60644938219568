import React, { useEffect } from "react"
import { Container, Row, Col } from "reactstrap"

const Landing = () => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="text-center">
                <img src="/logo-light.png" style={{width: 100}} />
                <h4 className="text-uppercase mt-4" style={{color: "white"}}>NonOpn</h4>
                <p className="text-muted">Our main product went to the moon</p>
                <div style={{marginTop: 50}}/>
                <p className="text-muted">Following this acquisition, our new project is coming soon mid 2023</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Landing
