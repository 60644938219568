import {
  CHANGE_LAYOUT,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_SIDEBAR,
  CHANGE_PRELOADER,
  CHANGE_MENU,
  TOGGLE_LEFTMENU,
} from "./actionTypes"
import { ChangeLayout, ChangeMenu, ChangePreloader, ChangeSidebarTheme, ChangeSidebarType, ChangeTopBarTheme, ShowRightSidebar, ShowSidebar, ToggleLeftMenu } from "./types"

export const changeMenu = (menu: "default"|"full"): ChangeMenu => ({
  type: CHANGE_MENU,
  payload: menu
})

export const changeLayout = (layout: "vertical"|"horizontal"): ChangeLayout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
})

export const changePreloader = (isPreloader: boolean): ChangePreloader => ({
  type: CHANGE_PRELOADER,
  payload: isPreloader,
})

export const changeSidebarTheme = (theme: "light"|"dark"|"colored"): ChangeSidebarTheme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})

export const changeSidebarType = (sidebarType: "compact"|"icon"|"condensed"|"default", isMobile?: boolean): ChangeSidebarType => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile: !!isMobile },
  }
}

export const changeTopbarTheme = (topbarTheme: "dark"|"light"): ChangeTopBarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
})

export const showRightSidebarAction = (isopen: boolean): ShowRightSidebar => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
})

export const showSidebar = (isopen: boolean): ShowSidebar => ({
  type: SHOW_SIDEBAR,
  payload: isopen,
})

export const toggleLeftmenu = (isopen: boolean): ToggleLeftMenu => ({
  type: TOGGLE_LEFTMENU,
  payload: isopen,
})
