import React, { Component } from "react"
import { Route } from "react-router-dom"
interface IComponent {
  new (name: string): Component;
}

interface Props {
  isAuthProtected: boolean,
  component: IComponent,
  path: string,
  location: any,
  layout: IComponent
}

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}: Props) => {
  return <Route
    {...rest}
    render={props => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
}

export default Authmiddleware
